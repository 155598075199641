import paytm from "../../assets/QR/Paytm.png";
import phonePE from "../../assets/QR/PhonePE.png";
import Gpay from "../../assets/QR/Gpay.png";
import cred from "../../assets/QR/CRED.png";

const qrData = [
  {
    name: "Paytm",
    upi: "nabeellaiq96@paytm",
    src: paytm,
  },
  {
    name: "Phone Pe",
    upi: "k.solutions2@axl",

    src: phonePE,
  },
  {
    name: "Gpay",
    upi: "update this",

    src: Gpay,
  },
  {
    name: "CRED",
    upi: "nabeelahmad390@axisb",

    src: cred,
  },
];

export default qrData;
