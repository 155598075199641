
import php from '../../../assets/php-developer.jpeg'
import wordpress from '../../../assets/wordpress-developer.jpeg'




const data = [
  {
    id:'1',
    image:php,
    header: "PHP DEVELOPER",
    height:'200px',
    para: "If you are thinking of hiring senior php developers and full-stack developers, then let’s talk!",
  },
  {
    id:'2',
    image:wordpress,
    height:'200px',
    header: "WORDPRESS DEVELOPER",
    para: "If you are thinking of hiring senior Wordpress developers and full-stack developers, then let’s talk!",
  },
  {
    id:'3',
    image:php,
    height:'200px',
    header: "WOOCOMMERCE DEVELOPER",
    para: "If you are thinking of hiring senior Woocommerce developers and full-stack developers, then let’s talk!",
  },
  {
    id:'4',
    image:wordpress,
    height:'200px',
    header: "REACT DEVELOPER",
    para: "If you are thinking of hiring senior ReactJS developers and full-stack developers, then let’s talk!",
  },
  {
    id:'5',
    image:php,
    height:'200px',
    header: "NEXTjs DEVELOPER",
    para: "If you are thinking of hiring senior NextJS developers and full-stack developers, then let’s talk!",
  }
  
];
export default data;
